<template>
  <a
    class="flex items-stretch p-3 hover:bg-gray-800 rounded-lg border border-gray-800 dark:bg-gray-850"
  >
    <div class="flex flex-col">
      <span class="text-white text-sm pt-1">{{ title }}</span>
      <span v-if="desc" class="text-gray-500 text-xs">{{ desc }}</span>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    desc: { type: String, default: null },
    title: { type: String, default: null },
  },
};
</script>
