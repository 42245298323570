<template>
  <div class="store">
    <StoreHeader type="store" />
    <StoreHeaderMenu />

    <main>
      <slot />
    </main>
    <StoreFooter />

    <!-- Modals -->
    <ModalProductVariant />
    <ModalChat />
    <ModalLang />
    <ModalProductView />
    <ModalMedia />
    <ModalLogin />
  </div>
</template>

<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
});

const headAndMeta = useHeadAndMeta();

// head is a reactive ref.
useHead(head);

// Static metadata
useHead(headAndMeta);
</script>
<style scoped>
.router-link-exact-active {
  color: #12b488;
}
</style>
