<template>
  <button
    class="inline-flex items-center bg-gray-800 rounded py-2 px-4 text-gray-400 hover:text-white"
    @click="$nuxt.callHook('modal-open', 'modal-lang')"
  >
    <p class="text-xl leading-none">{{ language.emoji }}</p>
    <p class="ms-2 text-sm leading-none">{{ language.name }}</p>
  </button>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$i18n.locales.find((locale) => {
        return locale.code === this.$i18n.locale;
      });
    },
  },
};
</script>
