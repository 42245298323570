<!-- eslint-disable vue/no-v-html -->
<template>
  <a
    class="inline-flex p-3 text-gray-500 bg-gray-800 rounded-full social-link hover:text-white"
  >
    <p class="w-5 h-5" v-html="$options.social[platform].icon.svg"></p>
  </a>
</template>

<script>
import social from "~/assets/social.js";
export default {
  social,
  props: {
    platform: { type: String, default: null },
  },
};
</script>

<style>
.social-link {
  path {
    fill: currentColor;
  }
}
</style>
