<template>
  <div>
    <div class="flex animate-pulse">
      <div class="h-24 w-24 bg-shimmer rounded-md dark:bg-gray-850"></div>
      <div class="ms-4 flex-grow">
        <div class="h-4 bg-shimmer rounded dark:bg-gray-850"></div>
        <div class="h-4 bg-shimmer rounded mt-1 w-3/4 dark:bg-gray-850"></div>
        <div class="flex">
          <div class="h-5 bg-shimmer rounded mt-2 w-16 dark:bg-gray-850"></div>
          <div
            class="ms-1 h-5 bg-shimmer rounded mt-2 w-12 dark:bg-gray-850"
          ></div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="h-4 bg-shimmer rounded mt-1 w-12 dark:bg-gray-850"></div>
      <div class="flex">
        <div class="w-16 h-8 mt-1 rounded bg-shimmer dark:bg-gray-850"></div>
        <div
          class="w-16 h-8 mt-1 rounded bg-shimmer ms-1 dark:bg-gray-850"
        ></div>
        <div
          class="w-16 h-8 mt-1 rounded bg-shimmer ms-1 dark:bg-gray-850"
        ></div>
        <div
          class="w-16 h-8 mt-1 rounded bg-shimmer ms-1 dark:bg-gray-850"
        ></div>
      </div>
    </div>

    <div class="flex mt-4 justify-between animate-pulse pt-6 mt-6 border-t">
      <div>
        <div class="h-2 bg-shimmer rounded w-12 dark:bg-gray-850"></div>
        <div class="h-6 w-16 bg-shimmer rounded mt-1 dark:bg-gray-850"></div>
      </div>
      <div class="w-32 h-10 rounded bg-shimmer dark:bg-gray-850"></div>
    </div>
  </div>
</template>
