<template>
  <div class="flex gap-2 items-center">
    <template v-for="(_, index) in 4" :key="index">
      <div
        class="w-24 h-6 bg-shimmer animate-pulse rounded-md dark:bg-shimmer-dark dark:bg-gray-850"
      />
    </template>
  </div>
</template>

<style>
.bg-shimmer {
  @apply bg-gray-200;
}

.dark .bg-shimmer-dark {
  @apply bg-gray-700;
}
</style>
