<template>
  <Modal id="modal-media" v-slot="{ close, data }" @open="enableZoom">
    <ModalShell title="Preview" :close="close" size="xl">
      <div class="md:p-4">
        <!-- Video -->
        <EmbededVideo
          v-if="data.mimetype && data.mimetype.startsWith('video')"
          :value="data.url"
        />

        <!-- Image -->
        <div v-else>
          <div id="pinch-zoom">
            <StoreProductMedia
              :border="false"
              size="1000x1000"
              :value="[data]"
            />
          </div>
          <div v-if="isTouch()" class="flex justify-center p-3">
            <Icon
              class="block w-8 h-8 text-gray-300"
              name="MaterialSymbolsPinch"
            />
          </div>
        </div>
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import PinchZoom from "pinch-zoom-js";

export default {
  methods: {
    isTouch() {
      return !!(
        "ontouchstart" in window ||
        ((window.navigator || {}).msPointerEnabled && window.MSGesture) ||
        window.DocumentTouch
      );
    },

    enableZoom() {
      this.$nextTick(() => {
        if (import.meta.client) {
          if (this.isTouch()) {
            const el = document.querySelector("#pinch-zoom");
            new PinchZoom(el);
          }
        }
      });
    },
  },
};
</script>

<style>
.pinch-zoom-container {
  height: 300px !important;
}
</style>
