<template>
  <footer class="footer">
    <div class="container">
      <!-- GRID -->
      <div class="grid gap-8 md:gap-16 md:grid-cols-3">
        <!-- COL: 1 -->
        <NuxtLazyHydrate never>
          <div class="text-center md:text-start">
            <img
              v-if="logo"
              class="w-24 m-auto md:m-0"
              :src="logo"
              :alt="`${name} Logo`"
              loading="lazy"
            />
            <p
              class="mt-6 text-xl font-bold text-white font-display dark:text-gray-300"
            >
              {{ name }}
            </p>
            <p class="text-sm text-gray-500">{{ desc }}</p>
          </div>
        </NuxtLazyHydrate>

        <!-- COL: 2 -->
        <NuxtLazyHydrate never>
          <div class="space-y-1">
            <StoreFooterOtherLink
              v-for="link in otherLink"
              :key="`link-${link.id}`"
              :href="link.url"
              :title="link.title"
              :desc="link.description"
              target="_blank"
            />
          </div>
        </NuxtLazyHydrate>

        <!-- COL: 3 -->
        <div class="text-center md:text-end">
          <NuxtLazyHydrate never>
            <div class="space-x-2 rtl:space-x-reverse">
              <StoreFooterSocialLink
                v-for="icon in socialLink"
                :key="`social-${icon.id}`"
                :platform="icon.platform"
                :href="icon.url"
                :title="icon.title"
                target="_blank"
              />
            </div>
          </NuxtLazyHydrate>
          <NuxtLazyHydrate never>
            <div class="flex flex-col mt-3 space-y-2">
              <VLink
                v-for="link in pages"
                :key="`page-${link.id}`"
                class="text-xs text-gray-500 hover:text-white"
                :to="{
                  name: 'biz-pages-slug',
                  params: { slug: link.slug },
                }"
              >
                {{ link.name }}
              </VLink>
            </div>
          </NuxtLazyHydrate>

          <VyButton
            class="!fixed bottom-20 right-4 lg:bottom-8 lg:right-8"
            size="xl"
            color="success"
            shape="circle"
            theme="solid"
            icon="MdiWhatsapp"
            @click="$nuxt.callHook('modal-open', 'modal-chat')"
          />

          <div class="mt-4">
            <LanguageSwitcher />
          </div>
        </div>
      </div>

      <!-- BOTTOM -->
      <NuxtLazyHydrate never>
        <div class="mt-6 text-center">
          <!-- ADDRESS -->
          <p class="pt-6 text-sm text-gray-500 border-t border-gray-800">
            {{ name }}<span v-if="address">,</span> {{ address }}
          </p>

          <!-- Vepaar Branding -->
          <div v-if="theme.vepaarBranding" class="mt-8">
            <p class="text-xs text-gray-500">Powered By</p>
            <a
              :href="`https://vepaar.com/online-store?utm_source=store&utm_medium=footer&utm_campaign=vepaar_branding`"
              target="_blank"
            >
              <img
                class="m-auto mt-1 w-36"
                src="https://vepaar.b-cdn.net/public/logo-light.png"
                alt="Vepaar Logo"
                loading="lazy"
              />
            </a>
          </div>

          <p class="mt-2 text-xs text-gray-600">
            Version {{ $options.version }}
          </p>
        </div>
      </NuxtLazyHydrate>
    </div>
  </footer>
</template>

<script>
import { useBizStore } from "@/store/index.js";
import { mapState } from "pinia";
import { version } from "../../../package.json";

export default {
  version,
  computed: {
    ...mapState(useBizStore, [
      "desc",
      "logo",
      "name",
      "otherLink",
      "socialLink",
      "pages",
      "address",
      "theme",
    ]),
  },
};
</script>

<style>
.footer {
  @apply bg-gray-900 py-12 px-4 mt-auto dark:bg-gray-850;
  @apply md:py-16;

  .container {
    padding-bottom: var(--headerHeight);
  }
}
</style>
