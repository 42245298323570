<template>
  <Modal id="modal-product-variant" v-slot="{ close, data }">
    <ModalShell :title="$t('select_options')" :close="close">
      <div class="p-4">
        <Query :query="productVarientGQL" :variables="{ id: data.id }">
          <template #loading>
            <ShimmerVariant />
          </template>

          <template
            #default="{
              data: {
                product: {
                  name,
                  media,
                  attributes,
                  isOutOfStock,
                  manageInventory,
                  price,
                  regularPrice,
                  status,
                  variants,
                },
              },
            }"
          >
            <StoreProductVariantInput
              v-bind="{
                attributes,
                isOutOfStock,
                price,
                regularPrice,
                manageInventory,
                status,
                variants,
                media,
              }"
            >
              <template #before="{ variantMedia }">
                <div class="flex mb-4">
                  <div class="w-24">
                    <StoreProductMedia
                      :value="variantMedia.length > 0 ? variantMedia : media"
                      size="500x500"
                      ratio
                    />
                  </div>
                  <h6 class="font-medium ms-4">{{ name }}</h6>
                </div>
              </template>

              <template
                #default="{
                  id,
                  stock,
                  isOutOfStock,
                  manageInventory,
                  notAvailable,
                  allowQty,
                }"
              >
                <div
                  class="grid items-center justify-between grid-cols-2 gap-2"
                >
                  <StoreBuyNow
                    :product-id="id"
                    :is-out-of-stock="isOutOfStock"
                    :not-available="notAvailable"
                    :max="stock"
                    :manage-inventory="manageInventory"
                  />

                  <StoreCartAddToCart
                    :id="id"
                    button-class="w-full"
                    qty-class="ms-auto"
                    :is-out-of-stock="isOutOfStock"
                    :manage-inventory="manageInventory"
                    :max="stock"
                    :allow-qty="allowQty"
                    type="variant"
                    :not-available="notAvailable"
                    theme="solid"
                    size="lg"
                  />
                </div>
              </template>
            </StoreProductVariantInput>
          </template>
        </Query>
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import { mapState } from "pinia";
import { useCartStore } from "@/store/cart";
import productVarient from "@/graph/store/product-variants.gql";

export default {
  data() {
    return {
      productVarientGQL: productVarient,
    };
  },
  computed: {
    ...mapState(useCartStore, ["productCount"]),
  },
};
</script>
