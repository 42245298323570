<template>
  <div class="relative z-20 dark:bg-gray-900 dark:text-gray-500">
    <!-- Overlay -->
    <div
      v-if="isMobileMenuOpen"
      class="fixed inset-0 z-10 cursor-pointer bg-black/70"
      @click="isMobileMenuOpen = false"
    ></div>

    <!-- Menu -->
    <div
      class="fixed -translate-x-full left-0 top-0 bottom-0 w-80 border-1 overflow-auto z-20 bg-white dark:bg-gray-900 transition-all lg:translate-x-0 lg:overflow-visible lg:top-[--headerHeight] lg:sticky lg:border-b lg:border-r-0 lg:shadow-none lg:w-auto"
      :class="{ 'translate-x-0': isMobileMenuOpen }"
    >
      <div class="relative lg:container">
        <nav class="overflow-x-auto divide-y lg:divide-y-0 lg:flex">
          <!-- Close  -->
          <div
            class="h-[--headerHeight] flex items-center bg-gray-100 dark:bg-gray-900 lg:hidden"
          >
            <p
              class="font-bold tracking-widest text-gray-500 uppercase ps-4 dark:text-gray-200 font-display"
            >
              Menu
            </p>
            <StoreHeaderButton
              class="ms-auto"
              icon="MdiClose"
              @click="isMobileMenuOpen = false"
            />
          </div>

          <!-- LINKS -->
          <StoreHeaderMenuShimmer v-if="isLoading" class="p-4" />

          <template v-else>
            <template
              v-for="(item0, index) in items"
              :key="`nav-item-0-${item0.id}`"
            >
              <!-- Top Level Nav Item -->
              <div class="group">
                <!-- Button -->
                <div class="flex">
                  <StoreHeaderMenuLink
                    :value="item0"
                    class="flex items-center flex-grow h-12 px-4 group-hover:text-primary-500 dark:group-hover:text-primary-600 whitespace-nowrap"
                    @click="isMobileMenuOpen = false"
                  >
                    {{ item0.label }}
                  </StoreHeaderMenuLink>
                  <StoreHeaderButton
                    v-if="item0.items.length"
                    class="lg:hidden"
                    :icon="
                      mobileActiveMenu == index
                        ? 'MdiChevronDown'
                        : 'MdiChevronRight'
                    "
                    @click="toggleMobileMenu(index)"
                  />
                </div>

                <!-- Desktop Mega Menu Container -->
                <div
                  v-if="item0.items.length"
                  class="hidden bg-white dark:bg-gray-900 lg:mx-16 lg:border dark:lg:border-gray-800 lg:absolute lg:left-0 lg:right-0 lg:hidden lg:shadow-xl lg:rounded-b-lg lg:max-h-[50vh] lg:p-8 lg:overflow-y-auto lg:group-hover:block"
                  :class="{ '!block': mobileActiveMenu == index }"
                >
                  <StoreHeaderMenuItem
                    :items="item0.items"
                    :index="1"
                    @open-menu="isMobileMenuOpen = $event"
                  />
                </div>
              </div>
            </template>
          </template>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import get from "./get.gql";
import { useBizStore } from "~/store";
export default {
  data() {
    return {
      mobileActiveMenu: null,
      isMobileMenuOpen: false,
      items: [],
      isLoading: false,
    };
  },

  computed: {
    ...mapState(useBizStore, ["subdomain"]),
  },

  created() {
    this.$bus.$on("openHeaderMenu", () => {
      this.isMobileMenuOpen = true;
    });

    this.getItems();
  },

  // beforeUnmount() {
  //   // removes event listener
  //   this.$bus.$off("openHeaderMenu", () => {
  //     this.isMobileMenuOpen = false;
  //   });
  // },

  methods: {
    getItems() {
      this.isLoading = true;
      // TODO -> SSR (data must be loaded server side.)
      this.$apollo
        .query({
          query: get,
          /**
           * Biz Query has two different schemas used in nuxtserverinit and here which is stored in cache
           * This Causes ERROR:
           * Cache data may be lost when replacing the biz field of a Query object.
           * This could cause additional (usually avoidable) network requests to fetch data that were otherwise cached.
           * Therefore using no cache fetch policy here.
           */
          fetchPolicy: "no-cache",
          variables: {
            subdomain: this.subdomain,
          },
        })
        .then((res) => {
          this.items =
            res.data.biz.stores?.[0].theme.menus.find(
              (menu) => menu.location === "header"
            )?.menu?.items || [];
          this.isLoading = false;
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleMobileMenu(index) {
      if (this.mobileActiveMenu === index) {
        this.mobileActiveMenu = null;
      } else {
        this.mobileActiveMenu = index;
      }
    },
  },
};
</script>
