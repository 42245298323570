<template>
  <div
    :class="{
      'w-full lg:columns-4 ps-6 lg:ps-0': index == 1,
      'lg:ps-0': index == 2,
      'ps-3 mb-3': index > 1,
    }"
  >
    <div
      v-for="(item, itemIndex) in items"
      :key="item.id"
      :class="{ 'break-inside-avoid': index == 1 }"
    >
      <!-- Item -->
      <div class="flex items-stretch">
        <div class="w-full" @click="$emit('openMenu', false)">
          <StoreHeaderMenuLink
            :value="item"
            class="hover:text-primary-300 flex items-center flex-grow"
            :class="{
              'text-md h-12 lg:font-semibold lg:text-primary-300 lg:h-auto':
                index == 1,
              'text-sm text-gray-700 h-8 flex items-center lg:h-auto':
                index != 1,
            }"
          >
            {{ item.label }}
          </StoreHeaderMenuLink>
        </div>
        <!-- Toggle Button -->
        <StoreHeaderButton
          v-if="index == 1 && item.items.length"
          class="lg:hidden"
          :icon="active == itemIndex ? 'MdiChevronDown' : 'MdiChevronRight'"
          @click="toggleMenu(itemIndex)"
        />
      </div>

      <!-- SubItems -->
      <StoreHeaderMenuItem
        v-if="item.items.length"
        class="lg:block"
        :class="{ hidden: active !== itemIndex && index <= 1 }"
        :items="item.items"
        :index="index + 1"
        @click="$emit('openMenu', false)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    index: { type: Number },
  },
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    toggleMenu(index) {
      if (this.active === index) {
        this.active = null;
      } else {
        this.active = index;
      }
    },
  },
};
</script>
