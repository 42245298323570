<template>
  <component
    :is="tag"
    :to="link"
    @click="handleExternalLinks(link)"
    class="cursor-pointer"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  setup() {
    const vLink = resolveComponent("VLink");
    return { vLink };
  },
  props: {
    value: Object,
  },
  computed: {
    tag() {
      switch (this.value.type) {
        case "category":
        case "product":
        case "page":
        case "system_page":
          return this.vLink;

        case "external":
          return "a";

        default:
          return "span";
      }
    },
    link() {
      switch (this.value.type) {
        case "category":
          return {
            name: "biz-categories-slug",
            params: { slug: this.value.slug },
          };

        case "product": {
          return {
            name: "biz-products-slug",
            params: { slug: this.value.slug },
          };
        }

        case "page": {
          return {
            name: "biz-pages-slug",
            params: { slug: this.value.slug },
          };
        }

        case "system_page": {
          return {
            // If slug is home for system pages, we don't need to use any slug for the route.
            name: this.value.slug !== "home" ? `biz-${this.value.slug}` : "biz",
          };
        }

        case "external": {
          return this.value.url;
        }

        default:
          return "#";
      }
    },
  },
  methods: {
    handleExternalLinks(link) {
      // let route = this.$router.resolve(link)
      navigateTo(link);
    },
  },
};
</script>
