<template>
  <div
    v-if="video"
    class="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden bg-black"
  >
    <iframe :src="video" allowfullscreen allowtransparency allow="autoplay" />
  </div>
</template>

<script>
import urlParser from "js-video-url-parser";
//  Video URLs: https://gist.github.com/jsturgis/3b19447b304616f18657

export default {
  props: {
    value: String,
  },

  computed: {
    video() {
      if (this.value) {
        const result = urlParser.parse(this.value);
        if (result) {
          switch (result.provider) {
            case "facebook":
              return `https://www.facebook.com/plugins/video.php?show_text=0&width=1600&height=900&href=${this.value}&autoplay=true&allowfullscreen=true`;

            case "youtube":
              return `https://www.youtube.com/embed/${result.id}?autoplay=1`;

            case "vimeo":
              return `https://player.vimeo.com/video/${result.id}?autoplay=1`;

            case "dailymotion":
              return `https://www.dailymotion.com/embed/video/${result.id}?autoplay=1`;

            case "wistia":
              return `https://fast.wistia.net/embed/iframe/${result.id}?autoPlay=true`;
          }
        }
      }
      return null;
    },
  },

  watch: {
    video(newValue) {
      this.$emit("change", newValue);
    },
  },
};
</script>
