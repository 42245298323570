<template>
  <Modal id="modal-lang" v-slot="{ close }">
    <ModalShell size="md" :title="$t('select_language')" :close="close">
      <div class="grid grid-cols-2 text-start gap-2 p-4 md:grid-cols-3">
        <button
          v-for="lang in $i18n.locales"
          :key="lang.value"
          @click="
            () => {
              $i18n.setLocale(lang.code);
              close();
            }
          "
          class="p-3 border rounded-md hover:ring-2 ring-primary-100 hover:border-primary-100 dark:bg-gray-900 dark:ring-gray-700 dark:hover:border-gray-700 dark:hover:ring-1 dark:border-gray-800 dark:border-2"
        >
          <div class="ms-2 flex flex-col text-start">
            <div class="text-4xl">{{ lang.emoji }}</div>
            <p class="leading-none text-sm">{{ lang.name }}</p>
            <p class="text-xs text-gray-500 mt-1">{{ lang.en_name }}</p>
          </div>
        </button>
      </div>
    </ModalShell>
  </Modal>
</template>
