<template>
  <Modal id="modal-chat" v-slot="{ close }">
    <ModalShell size="md" :title="$t('chat_with_us')" :close="close">
      <div class="p-4 space-y-2">
        <BizContact
          v-for="(contact, i) in mobiles"
          :key="`contact-${i}`"
          :name="contact.name"
          :mobile="contact.mobileNumber"
          :desc="contact.designation"
          :profile="contact.profile"
          :whatsapp-support="contact.whatsappSupport"
          :call-support="contact.callSupport"
          :is-available="contact.isAvailable"
        />
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import { mapState } from "pinia";
import { useBizStore } from "@/store/index.js";
export default {
  computed: {
    ...mapState(useBizStore, ["mobiles"]),
  },
};
</script>
