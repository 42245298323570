<template>
  <Modal id="modal-product-view" v-slot="{ close }">
    <ModalShell size="sm" :title="$t('change_view')" :close="close">
      <DropdownList
        :options="$options.views"
        :value="productView.value"
        @update="setData"
      />
    </ModalShell>
  </Modal>
</template>

<script>
import views from "@/assets/product-views";
import { useProductPrefStore } from "@/store/pref.js";
import { mapState, mapActions } from "pinia";

export default {
  views,
  computed: {
    ...mapState(useProductPrefStore, ["productView"]),
  },
  methods: {
    ...mapActions(useProductPrefStore, ["set"]),

    setData(value) {
      this.set({
        key: "view",
        value,
      });
      this.$nuxt.callHook("modal-close", "modal-product-view");
    },
  },
};
</script>
